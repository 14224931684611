@use "./variables.scss" as *;

table.adapters {
    box-shadow: 1px 1px 2px grey;
    border: 1px;

    td {
        width: auto;
        vertical-align: middle;
        border: 1px dashed #cccccc;
        border-right-width: 0px;
        border-left-width: 0px;
    }

    td:hover { 
        background:  #eeeeee;
    }

    td.no-adapters {
        width: auto;  
        text-align: center; 
        color: #aaa;
        font-style: italic;
        border: 0px;
    }

    td.alpha-adapters {
        width: auto; 
        background-color: lightgray;
        text-align: center; 
        border: 0px;
    }

    td.beta-adapters {
        width: auto;  
        background-color: $lightgreen;
        text-align: center; 
        color: #fff;
        border: 0px;
    }

    td.stable-adapters {
        width: auto; 
        background-color: $coolgray;
        text-align: center; 
        color: #fff;
        border: 0px;
    }

    tr:hover {
        background-color: #f5f5f5;
    }

    img.adapter-logo {
        vertical-align: middle;
        margin-right: 5px;
        height: 30px;
        width: 30px;
    }

}