@use "./variables.scss" as *;

/* modal background */
.modal-video {
    display: none;                  /* Hidden by default */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;                      /* Sit on top */
    width: 100%;                      /* Full width */
    height: 100%;   
    overflow: auto;
    overflow-x: hidden;                   /* Enable scroll if needed */
    background-color: rgb(0,0,0);   /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    transition: all 0.3s;
 
    /* modal content */
    .modal-content-video {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background-color: $coolgray;
        color: white;
        width: 80%;
        margin-top: 10%;
        padding: 0rem 1.5rem 1.5rem 1.5rem;
        border-radius: 1%;
    & h3 { 
        font-size: 2rem !important;
        color: var(--color-white);
    }
    }
 
    /* close button */
    .close-video {
        color: #aaaaaa;
        font-weight: bold;
        padding: 0; margin:0;
    }
    .close-video:hover,.close-video:focus {
        text-decoration: none;
        cursor: pointer;
        color: $lightgreen;
    }
}
  
