@use "./variables.scss" as *;
.catalog-heading {
  text-align: center;
  font-weight:700;
  margin: 2.5rem 0 1rem 0;
  word-spacing: 5px;
  font-size: 2.5rem;
}
.catalog-subheading {
  text-align: center;
  color: var(--color-secondary-dark);
  font-weight: 500;
  margin: 1rem auto;
  font-size: 2rem;
}
.catalog-subtext {
  text-align: center;
  color: var(--color-secondary-dark);
  font-size: 18px;
  font-weight: 400;
}
.tabcontent h3 {
  color: var(--color-secondary-dark);
}
/* CARD-STYLES */
.card {
  position: relative;
  width: 220px;
  height: 250px;
  border-radius: 15px;
  border: 0;
  transition: 0.4s ease-out;
  margin-bottom: 20px;
  display: block;
  margin-bottom: 20px;
  perspective: 1000px;
  transition: all 0.9s;

  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }

  &:hover .card-inner {
    // transform: rotateY(180deg);
    transform: translateY(-2%);
  }

  .front,
  .back {
    box-shadow: 2px 2px 6px 0px #00d3a9;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    border-radius: 15px;
    backface-visibility: hidden;
  }

  .front {
    background: var(--background-light-cards);
  }

  .back {
    color: white;
    transform: rotateY(180deg);
    background: var(--background-primary);
  }
}

.card:hover {
  // transform: rotateY(180deg);
  cursor: pointer;
  // .chip {
  //   // background: rgba(182, 215, 168, 0.4);
  // }
}
.pattern-image-overlay {
  opacity: 0.2;
  width: 60%;
  object-fit: contain;
  position: absolute;
  margin-left: 5%;
  border-radius: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-overlay {
  position: relative;
  width: 220px;
  height: 250px;
  // padding: 20px;
  border-radius: 15px;
  border: 0;
  background: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 2px 2px 6px 0px #00d3a9;
  margin-bottom: 20px;
}
.overlay-text {
  position: absolute;
  top: 40%;
  left: 50%;
  font-size: 19px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 2;
  white-space: wrap;
}
.pattern-id-overlay {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  padding-top: 10px;
  margin-right: 14px;
  opacity: 0.7;
}
.catalog {
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;

  & .row {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 75%;
    gap: 1rem;
    column-gap: 2rem;
    justify-content: center;
    height: fit-content;
    padding-top: 1rem;
  }
  #sort, #search {
    background-color: transparent;
    color: var(--color-primary-qtext);
  }
  #sort:focus {
    background-color: var(--color-primary-light);
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
}

body.dark-mode #sort {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M7 10l5 5 5-5z"/></svg>');
}

p {
  font-family: "Qanelas Soft", "Open Sans", "sans-serif";
  -webkit-font-smoothing: antialiased;
  font-size: 1.1rem;
}
.inner-span {
  font-size: 16px;
  position: relative;
  color: black;
}
.column {
  display: none;
}
.column:has(.card) {
  // float: left;
  // padding: 0 10px;
  display: block;
  // margin: 0 1rem;
}
.smp-overlay {
  width: 16px;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  vertical-align: middle;
  padding-right: 1px;
}
.smp {
  width: 16px;
  bottom: 0;
  right: 0;
  vertical-align: middle;
  padding-right: 1px;
}

.import {
  background-color: #00b39fff;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.import-disabled {
  background-color: #aaa;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 10px;
}
.import-secondary {
  background-color: inherit;
  border:1px solid #00b39f;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
}
.btn-tooltip-grp {
  display: flex;
  flex-direction: row;
  position: relative;
}

.vertical-center {
  // -ms-transform: translateY(-60%);
  // transform: translateY(25%) translateX(0%);
}
/* Clear floats after the columns */

.disabled-label {
  color: #aaa;
}

.dropdown-icon {
  width: 20px;
  vertical-align: middle;
  padding: 2px;
}

/* CARD-STYLES */
.pattern-type {
  cursor: inherit;
  display: flex-start;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  color: var(--color-primary-light-tbase);
}
.chip {
  display: inline-flex;
  flex-direction: row-reverse;
  // margin: 4px;
  // background-color: rgb(182, 215, 168);

  border: none;
  cursor: default;
  height: 1.5rem;
  outline: none;
  padding-left: 2px;
  padding-right: 3px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  float: left;
}
  .chip.COLLABORATION {
    background-color: var(--integrations-bg);
    box-shadow: 0px 1px 2px var(--integrations-bg);
    .pattern-type {
      box-shadow: none;
      background-color: none;
      color: var(--color-secondary-medium);
    }
  }
  .chip.LIFECYCLE {
    background-color: var(--brand-color-tertiary);
    box-shadow: 0px 1px 2px var(--brand-color-tertiary);
    .pattern-type {
      box-shadow: none;
      background-color: none;
      color: var(--color-secondary-medium);
    }
  }
  .chip.GITOPS {
    background-color: var(--action-color-dark);
    box-shadow: 0px 1px 2px var(--action-color-dark);
    color: var(--color-primary-dark);
  }
  .chip.CONFIGURATION {
    background-color: var(--action-color-light);
    box-shadow: 0px 1px 2px var(--action-color-light);
    color: var(--color-primary-dark);
  }

.category-label {
  display: inline;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  white-space: nowrap;
  border-radius: 1rem;
  background-color: #b6d7a8ff;
  padding: 0.5rem;
  margin: auto;
}

.chip-modal {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 2.5rem;
  font-size: 1.6rem;
  background-color: #00d3a9;
  align-self: flex-end;
  float: right;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
}

.pattern-name {
  color: var(--color-secondary-light);
  font-weight: 1400;
  font-style: bold;
  width: calc(80%);
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 15px 0 15px 0;
}

.pattern-id {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  left: 0;
  margin: auto;
  color: var(--color-secondary-light);
  // margin: 0 3px 3px 0;
}
.pattern-image-container {
  display: flex;
  justify-content: center;
}
.pattern-image, .model-image {
  aspect-ratio: 1;
  width: 60%;
  margin-top: 24px;
  object-fit: contain;
  position: absolute;
}
.patterns-coming-soon {
  max-width: 70%;
  position: relative;
  float: right;
  z-index: 2;
  margin-top: -2.5rem;
  margin-right: -2rem;
  transform: translateY(0%) translateX(0%) rotate(10deg);
  -webkit-transform: translateY(0%) translateX(-0%) rotate(10deg);
  -moz-transform: translateY(0%) translateX(-0%) rotate(10deg);
  -ms-transform: translateY(0%) translateX(-0%) rotate(10deg);
  -o-transform: translateY(0%) translateX(-0%) rotate(10deg);
}
.extensions-coming-soon {
  width: 50%;
  height: 50%;
  position: relative;
  float: right;
  z-index: 2;
  margin-top: -0.5rem;
  margin-right: -7rem;
  transform: translateY(0%) translateX(0%) rotate(-10deg);
  -webkit-transform: translateY(0%) translateX(-0%) rotate(-10deg);
  -moz-transform: translateY(0%) translateX(-0%) rotate(-10deg);
  -ms-transform: translateY(0%) translateX(-0%) rotate(-10deg);
  -o-transform: translateY(0%) translateX(-0%) rotate(-10deg);
  
}

.hidden {
  visibility: hidden;
}

.external-link {
  display: inline-block;
  width: 1rem;
  img {
    margin-bottom: 3px;
    &:hover {
      opacity: 0.7;
    }
  }
}
.internal-link {
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  vertical-align: middle;
  filter: var(--image-filter-light);
  &:hover {
    opacity: 0.7;
  }
}

/* CHECKBOX-STYLES */
.filter-wrap {
  // float: left;
  flex: 0 0 25%;
  width: 23%;
  padding: 4px;
  border-radius: 15px;
  // margin-top: 5rem;
  // backgroud:var(--background-grey);
  height: fit-content; /* height needs to be increased with regarding to the total no. of patterns */
  .load-more {
    display: none;
  }
}
label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
}

input {
  width: 20px;
  height: 20px;
  padding: 3px;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
  display: inline;
}
input[type="checkbox"] {
  margin: 0;
}

/* MODAL-STYLES */
.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-section {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}
.modal-image {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  padding: 2rem;
  min-height: fit-content;
  background: (var(--color-primary-light-dark));
  img {
    max-width: 80%;
    // margin-top: 25px;
  }
}

.image-class {
  max-width: 80%;
  margin: 36px 0;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: "none";
}

.modal-content {
  display: flex;
  flex-direction: row;
  code {
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    background-color: #1a2421;
    line-height: 1rem;

    color: #eee;
    display: block;
    // & p ul {
    text-align: left;
    // }
    a {
      color: white;
    }
  }
  p {
    color: var(--color-secondary-dark);
  }
}
.modal-h2 {
  color: var(--color-secondary-dark);
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: inherit;
  font-weight: 600;
  font-family: "Qanelas Soft", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0.5rem;
}

.modal-h3 {
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0rem;
  text-transform: uppercase;
  font-size: 1.15rem;
}

.modal-info {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 28px;
  gap: 1rem;
}

.modal-window > div {
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
  width: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  background: linear-gradient(
    45deg,
    var(--color-primary-dark),
    var(--color-primary-light)
  );
  // padding: 1.5rem 2.5rem 1.5rem 3rem;

  .modal-info {
    overflow-y: auto;
  }
}
.modal-window > div::-webkit-scrollbar {
  display: none;
}
.modal-info::-webkit-scrollbar {
  display: none;
}
.catalog-info {
  text-align: center;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  display: none;
}

.modal-small-header {
  font-size: 15px;
  display: block;
}

.service-mesh-image {
  height: 50px;
  width: 50px;
}
.modal-close {
  display: flex;
  justify-content: space-between;
  color: var(--brand-color-secondary);
  line-height: 1rem;
  font-size: 1rem;
  display: flex;
  text-align: center;
  text-decoration: none;
  img {
    width: 1rem;
    vertical-align: middle;
  }
}
.modal-pattern-name {
  color: var(--color-secondary-dark);
  text-align: center;
  font-weight: 800;
  font-size: 12px;
  white-space: nowrap;
  padding-top: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.modal-chip {
  display: inline-flex;
  flex-direction: row-reverse;
  float: right;
  background-color: var(--brand-color-primary);
  border: none;
  cursor: default;
  height: 1rem;
  outline: none;
  padding: 0.1rem 0.5rem;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 0 0 0 5px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
}
.modal-card {
  overflow: hidden;
  position: relative;
  background: var(--background-light-cards);
  width: 150px;
  height: 180px;
  padding: 20px;
  border-radius: 15px;
  border: 0;
  transition: 0.4s ease-out;
  box-shadow: 2px 2px 6px 0px #00d3a9;
}
.modal-smp {
  width: 20px;
  bottom: 0;
  right: 0;
  vertical-align: middle;
  padding-right: 2px;
}
.modal-pattern-image {
  width: 60%;
  object-fit: contain;
  position: absolute;
  margin-top: 16px;
}
.modal-pattern-id {
  color: var(--color-secondary-dark);
  position: absolute;
  bottom: 0;
  font-size: 8px;
  right: 0;
  margin: 0px 64px 4px 0px;
}

.modal-container {
  padding: 20px 40px 40px;
  max-width: 600px;
}
.modal-text {
  display: inline;
}
.image-container {
  flex-basis: 70%;
}

.vertical-line {
  border-left: 1px solid var(--color-secondary-light);
  // height: 114%;
}

.redirect {
  width: 0rem;
  color: var(--brand-color-secondary);
}

.close {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  margin-right: 18px;
  top: 18px;
  float: right;
  transition: all 200ms;
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-secondary-dark);
  background: transparent;
  border: 0px;
  padding: 0px;
  cursor: pointer;
}
.close:hover {
  color: #00d3a9 !important;
  opacity: 0.8 !important;
  text-decoration: none;
}

open-modal-btn {
  display: block;
  margin: 0 auto;
}
.btn-grp {
  margin-top: 1rem;
  display: flex;
  gap: 1.5rem;
  font-size: 1rem;
}
.tooltip-modal:hover {
  text-decoration: none;
}

.catalog-details li span {
  font-size: 0.25rem;
}
.catalog-item-detail {
  color: var(--color-secondary-dark);
}

.pattern-filter-image {
  display: flex;
  width: 50%;

  object-fit: contain;
  margin: auto;
}

.wasm {
  width: 15px;
  bottom: 0;
  right: 0;
  vertical-align: middle;
  padding-right: 2px;
  padding-bottom: 3px;
}
label {
  white-space: nowrap;
  color: var(--color-secondary-dark);
  text-overflow: ellipsis;
  overflow: hidden;
}

.related-patterns {
  font-size: 1rem;
}

.modal-smp {
  width: 20px;
  bottom: 0;
  right: 0;
  vertical-align: middle;
  padding-right: 2px;
}
// .modal-pattern-id {.
//   position: absolute;
//   bottom: 0;
//   font-size: 8px;
//   right: 0;
//   padding-top: 10px;
//   margin-right: 14px;
// }
// .modal-window div div:not(:last-of-type) {
//   margin-bottom: 15px;
// }
.modal-container {
  padding: 20px 40px 40px;
  max-width: 600px;
}
.modal-text {
  display: inline;
}
.image-container {
  flex-basis: 70%;
}

.related-patterns-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-pattern-image-overlay {
  opacity: 0.5;
  width: 55%;

  object-fit: contain;
  position: absolute;
  margin-left: 5%;
  border-radius: 15px;
}
.modal-card-overlay {
  position: relative;
  width: 150px;
  height: 180px;
  padding: 20px;
  border-radius: 15px;
  border: 0;
  background: $catalog_modal_background;
  box-shadow: $catalog_box_shadow_color;
  margin-bottom: 20px;
}
.modal-smp-overlay {
  width: 20px;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  vertical-align: middle;
  padding-right: 2px;
}
.modal-pattern-id-overlay {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  left: 0;
  // padding-top: 10px;
  margin: auto;
  color: white;
  opacity: 0.7;
}
.modal-overlay-text {
  position: absolute;
  top: 40%;
  left: 50%;
  color: white;
  font-size: 15px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 2;
  white-space: nowrap;
}

.mobile_column_sm {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;

  &:nth-child(1) {
    margin-top: 3rem;
  }
}
.catalog-load-more {
  display: none;
}
.dropbtn {
  display: none;
}
.contentdata {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Limit to 3 lines, adjust as needed */
  -webkit-line-clamp: 5;
}

.pre-white-space {
  white-space: pre-wrap;
}

.read-more {
  cursor: pointer;
  color: var(--brand-color-primary);
}

.visible-content {
  display: inline; /* Initially show the visible content */
}

.hidden-content {
  display: none; /* Initially hide the hidden content */
  max-height: none; /* Remove the max-height limit to allow it to expand */
  opacity: 1; /* Set opacity to fully visible */
  overflow: visible; /* Allow the content to be fully visible */
  transition: max-height 0.5s, opacity 0.5s;
}
.hide-by-default {
  display: none;
}

@media screen and (max-width: 700px) {
  .modal-window {
    width: 100%;
    height: 100%;
    align-items: center;
  }
  .btn-tooltip-grp {
    justify-content: center;
  }
}

@media screen and (max-width: 1150px) {
  // .modal-info {
  //   overflow-y: auto;
  // }
  .modal-window > div {
    height: 80%;
    overflow-y: initial;
    flex-direction: column;
    left: 50%;
    padding: 0 28px;
    text-align: center;
    .modal-info {
      padding-top: 0;
      overflow-y: initial;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    code {
      margin: auto auto;
    }
  }
  .modal-smp {
    width: 20px;
    bottom: 0;
    right: 0;
    vertical-align: middle;
    padding-right: 2px;
  }
  // .modal-pattern-id {
  //   position: absolute;
  //   bottom: 0;
  //   font-size: 8px;
  //   right: 0;
  //   padding-top: 10px;
  //   margin-right: 14px;
  // }
  .modal-container {
    padding: 20px 40px 40px;
    max-width: 600px;
  }
  .modal-text {
    display: inline;
  }
  .image-container {
    flex-basis: 70%;
  }

  .modal-pattern-image-overlay {
    opacity: 0.5;
    width: 55%;

    object-fit: contain;
    position: absolute;
    margin-left: 5%;
    border-radius: 15px;
  }
  .modal-card-overlay {
    position: relative;
    width: 150px;
    height: 180px;
    padding: 20px;
    border-radius: 15px;
    border: 0;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 2px 2px 6px 0px #00d3a9;
    margin-bottom: 20px;
  }
  .modal-smp-overlay {
    width: 20px;
    bottom: 0;
    right: 0;
    opacity: 0.7;
    vertical-align: middle;
    padding-right: 2px;
    padding-bottom: 3px;
  }
  .modal-pattern-id-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-top: 10px;
    margin-right: 14px;
    color: white;
    opacity: 0.7;
  }
  .modal-overlay-text {
    position: absolute;
    top: 40%;
    left: 50%;
    color: white;
    font-size: 15px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 2;
    white-space: nowrap;
  }
  .close {
    display: flex;
    justify-content: space-between;
    transition: all 200ms;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    background: transparent;
    border: 0px;
    padding: 0px;
    cursor: pointer;
  }
  .catalog-info {
    text-align: center;
  }

  .btn-grp {
    flex-direction: row;
    gap: 1rem;
    // padding-bottom: 2rem;
    width: 100%;
    justify-content: center;
  }

  .tooltip-modal {
    position: relative;
    & .tooltiptext-modal {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transition: all 0.5s;
    }
  }
  .related-patterns-img {
    display: none;
  }
  .hide-by-default {
    display: flex;
  }

  .modal-info {
    width: 100%;
    // padding: 4px 32px;
  }
  .modal-image {
    padding-bottom: 0;
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    width: 80%;
    background-color: transparent;

    img {
      max-width: 30%;
    }
  }
  .modal-info {
    text-align: left;
  }

  .modal-h2 {
    text-align: center;
    font-size: 1.5rem;
    margin-top: 0;
  }
}
.viewall {
  display: flex;
  padding: 5px;
  margin: 5px;
}
.viewallbtn {
  cursor: pointer;
  border: none;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  border-radius: 7px;
  padding: 0.5rem;
  background: #00b39f;
}
.viewallbtn:hover{
  color:#fff;
}

@media screen and (max-width: 650px) {
  .modal-window {
    width: 100%;
    height: 100%;
  }
  .modal-window > div {
    overflow-y: initial;
    .modal-info {
      padding-top: 0;
      overflow-y: initial;
    }
  }
}
@media screen and (min-width: 770px) {
  .viewallbtn {
    position: relative;
  }
}
@media screen and (max-width: 770px) {
  .catalog-load-more {
    display: unset;
  }
  .dropbtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .viewall {
    justify-content: center;
  }
  .catalog {
    .row {
      display: flex;
      // flex-direction: column;
      column-gap: 1.75rem;
      row-gap: 0.5rem;
      align-items: center;
      margin-left: 0.5rem;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .column-lg:has(.card) {
      display: block;
    }
    .mobile_column_sm {
      display: unset;
    }
  }

  .filter-wrap {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 1rem;
    // padding-bottom: 3rem;
    margin-bottom: .15rem;
    .dropdown {
      align-self: center;
      margin-bottom: 7rem;
    }
    .category-div {
      padding-left: 15px;
    }
    .compatibility-div {
      margin-bottom: 3rem;
      margin-top: -1rem;
    }
    .category-div,
    .compatibility-div,
    .tech-div {
      display: grid;
      label {
        margin: 0;
      }
      column-gap: 2rem;
      .open-service-mesh {
        grid-row: 4/5;
        grid-column: 1/3;
      }
    }
    .category-para,
    .category-div,
    .compatibility-div,
    .tech-div,
    .compatibility-para,
    .tech-para {
      display: none;
    }
    .load-more {
      display: block;
      align-self: center;
    }
  }
}
@media screen and (max-width: 770px) and (min-width: 450px) {
  .category-div,
  .compatibility-div,
  .tech-div {
    row-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 450px) {
  .category-div,
  .compatibility-div,
  .tech-div {
    row-gap: 1rem;
  }
}

@media screen and (max-width: 644px) {
  .modal-image {
    // width: 230px;
    gap: 0;
    // display: contents;

    img {
      max-width: 40%;
    }
  }
  .modal-h1,
  .related-patterns {
    display: none;
  }
  .btn-grp {
    // flex-direction: column;
    align-items: center;
  }
}
