.card-div {
    background: transparent !important;
    padding-top: 0rem !important;
    .card{
        width: 130px;
        height: 130px;
        background:var(--background-primary);
        h1 {
            font-size: 1.5em;
            color: var(--color-white);
        }
    }
}

  .cohort_title {
    color: var(--color-secondary-dark) !important;
    text-align: center;
    margin: 2rem  0rem;
  }

.program-heading {
    background: transparent !important;
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
    margin-top: 0 !important;
    color: var(--color-secondary-medium) !important;
}

.programlist-subscribe {
    background: transparent !important;
}

.programlist-subscribe .form-container {
    margin: 3rem auto !important;
    padding: 2rem 0rem;
    box-shadow: var(--box-shadow-primary);
}

.program .project-details {
    max-width: 100%;
}
