@use 'sass:color';
$brand-color: #3c494f;
$secondary-brand-color: #00d3a9;
$middle-gradient-color: color.mix($brand-color, $secondary-brand-color, 95%);
$primary-brand-color: #1a2421;
$menu-color: grey;

// Breakpoints
$mobile: "(max-width: 420px)";
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";

// Meshery Colors
$coolgray: #3c494f;
$lightgreen: #00d3a9;
$darkgreen: #00b39f;

//catalog
$catalog_modal_background: rgba(0, 0, 0, 0.7);
$catalog_box_shadow_color: #00d3a9;

