@use "./mixins/transitions.scss" as *;
.calendar-meetings {
  max-width: 1140px;
  margin: auto;
  text-align: center;
  color: var(--color-secondary-light);

  h1 {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }

  p {
    margin: 0;
  }
  .fc-button {
    transition: all .4s;
    border: none;
    background-color: var(--brand-color-secondary) !important;
    &:hover{
      background-color: var(--brand-color-primary) !important;
    }
  }
  .fc-right > .fc-addToCalendar-button {
    background-color: var(--action-color-dark) !important;
    &:hover{
      background-color: var(--action-color-dark-hover) !important;
    }
  }
  @media (max-width: 574px) {
    .fc-prev-button,.fc-next-button,.fc-today-button{
      margin: 5px;
    }
  }
}
