@use "./variables.scss" as *;
@use 'mixins/flexbox' as *;
.footer-links {
  width: 100%;
  margin: 10px 20px;
  padding: 0;

  @media #{$tablet} {
    -webkit-flex: 0 0 fit-content;
    flex: 0 0 fit-content;
  }

  li {
    list-style: none;
    margin: 10px auto;

    @media #{$tablet} {
      max-width: 200px;
    }
    a {
      &:hover {
        text-decoration: none;
      }
      svg {
        fill: #999;
        margin-right: 10px;
        transition: fill 0.2s ease;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 22px;
        height: 22px;
      }

      &:hover svg {
        fill: #fff;
      }

      &.twitter-icon:hover svg {
        fill: var(--color-white);
      }

      &.google-plus-icon:hover svg {
        fill: #db4437;
      }

      &.dockerhub-icon:hover svg {
        fill: #029cec;
      }

      &.youtube-icon:hover svg {
        fill: #cd201f;
      }

      &.layer5-icon:hover svg {
        fill: #00b39f;
      }

      &.linkedin-icon:hover svg {
        fill: #0077b5;
      }

      &.bluesky-icon:hover svg {
        fill: #1185fe;
      }

      &.slack-icon:hover svg .first{
        fill: #e91e63;
      }
      &.slack-icon:hover svg .second{
        fill: #e91e63;
      }
      &.slack-icon:hover svg .third{
        fill: #00bcd4;
      }
      &.slack-icon:hover svg .fourth{
        fill: #00bcd4;
      }
      &.slack-icon:hover svg .fifth{
        fill: #4caf50;
      }
      &.slack-icon:hover svg .sixth{
        fill: #4caf50;
      }
      &.slack-icon:hover svg .seventh{
        fill: #ff9800;
      }
      &.slack-icon:hover svg .eigth{
        fill: #ff9800;
      }

      &.github-icon:hover svg {
        fill: #fff;
      }

      &.rss-icon:hover svg {
        fill: #f26522;
      }
    }
  }
}

.feed {
  width: 100%;
  margin: 10px 20px;
  padding: 0;

  @media #{$tablet} {
    -webkit-flex: 0 0 fit-content;
    flex: 0 0 fit-content;
  }

  @media (min-width:820px) and (max-width: 1154px){
    margin-top: 25px;
    text-align: justify;
  }

  ul {
    list-style: none;
    margin: 10px auto;

    @media #{$tablet} {
      max-width: 400px;
    }
  }
}

footer {
  padding: 50px 0 50px 0;
  font-size: 0.9rem;
  position: relative;
  background: linear-gradient(to right top, #3d3d3d, #343434, #2b2b2b, #222222, #1a1a1a, #181818, #161616, #141414, #181818, #1c1c1c, #212121, #252525);;
  color: #fff;

  .copyright {
    font-size: 0.9rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: $coolgray 1px solid;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    @media (max-width: 1000px) {
      flex-direction: column;
      text-align: center;
      .text {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .twitter-timeline {
    border-radius: 50%;
    justify-content: center;
  }

  .layer5-footer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    img {
      width: 120px;
      height: 30px;
    }
  }

  &,
  a {
    color: #999;
  }

  h2 {
    font-size: 1.2em;
    margin: 30px 0;
    margin: 15px auto;
    color: #ccc;
    font-weight: bold;
    text-align: start !important;
  }
  

  .footer-columns {
    @extend %flexbox;
    @include flex-flow(wrap);
    justify-content: space-evenly;
  }

  a {
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  .legal-line {
    width: 100%;
    padding: 30px 0;
    margin: 0;
    background-color: #222527;

    a {
      font-weight: 600;
    }
  }
}
