@mixin btn-hover-primary {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::after {
        content: "";
        top: 0;
        left: -30%;
        width: 130%;
        position: absolute;
        height: 100%;
        background: var(--background-primary);
        transition: all .4s;
        z-index: -1;
        border-radius: inherit;
    }

    &:active {
        transform: scale(1);
    }

    &:hover {
        color: var(--color-white) !important;
        border-color: var(--color-secondary-dark);
        transform: scale(1.01);

        &::after {
            width: 180%;
        }
    }
}


@mixin btn-hover-secondary {
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 0%;
        position: absolute;
        height: 100%;
        background: linear-gradient(to right bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        transition: all .4s;
        z-index: -1;
        border-radius: inherit;
    }

    &:hover {
        & * {
            color: #fff !important;
            border-color: white;
        }

        &::after {
            width: 100%;
        }
    }
}