.sitewide-banner {
    overflow-y: hidden;
    position:relative;
    animation:animatetop 0.8s;
    // top: 100%; 
    display: flex;
    // margin-bottom: 1rem;
    flex-direction: row;
    justify-content: center;
    color: var(--color-secondary-dark);
    flex-wrap: wrap;
    padding: .8rem 1.1rem;
    background-color:  var(--brand-color-secondary);    
    font-size: 1.05rem;
    align-items: center;
    p {
        padding: 0rem;
        margin: 0rem;
    }
    a {
        display: flex;
        color: white;   
        margin-right: 2rem;
        margin-left: 2rem;
        padding: .9rem;
        border-radius: 1rem;
        border: 1px white solid;
        &:hover {
            background-color: white;
            color: var(--brand-color-secondary);
        }
    }
    @media (max-width: 960px) {
            font-size:.9rem;
            text-align: center;
            text-wrap:wrap;
            
            padding: .5rem .75rem;
            a {
                margin-top: .75rem;
                padding: .5rem;
            }
            p {
                margin: .5rem;
            }
        }

        
}
@keyframes animatetop {
    from {
        top:-300px;
        opacity:0
    } 
    to {
        top:0;
        opacity:1
    }
}

.sitewide-banner.hidden {
    // top: -100%;
    max-height: 0;
    
  }