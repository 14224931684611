@use "./mixins/transitions.scss" as *;
@use "./variables.scss" as *;
.form-container{
	background: var(--background-grey-secondary);
	border-radius: 0.625rem;
	box-shadow: var(--box-shadow-primary);
	& p{
		color: var(--color-primary-light) !important;
	}
}
.button a, input[type=submit] {
	color: #fff;
	text-decoration: none;
	padding: 10px 30px;
	border-radius: 3px;
	transition: .2s ease-in-out;
	border: none;
	border-radius: 7px; 
	position: relative;
	z-index: 2;
    transition: all .4s;
    border: none;
    background-color: var(--brand-color-primary) !important;
    &:hover{
      background-color: var(--brand-color-secondary) !important;
    }
}
.subscribe-button{
	box-sizing: border-box;
	position: relative;
	color: white;
	text-decoration: none;
	padding: 10px 30px;
	border-radius: 3px;
	transition: .2s ease-in-out;
	border: none;
	border-radius: 7px;
	position: relative;
	z-index: 2;
	overflow: hidden;
	margin: 2rem 0 0 0;
	appearance: none;
	cursor: pointer;
	background: transparent !important;
	box-shadow: 0 0 40px 40px var(--brand-color-secondary) inset, 0 0 0 0 var(--brand-color-secondary);
	transition: all 200ms ease-in-out;
	&:hover{
		transform: scale(1.02);
		color: #fff;
		outline: 0;
		box-shadow: 0 0 10px 0 var(--brand-color-primary) inset, 0 0 5px 1px var(--brand-color-primary);
		background-color: var(--brand-color-primary) !important;
	}
}

.button a:hover, input[type=submit]:hover {
	cursor: pointer;
	opacity: 1;
	background-color: #00B39F;
}

.button-para{
	width: fit-content;

	&--center{
		display: contents;
	}
}

.button-para .link{
	display: flex;
	border: none;
	flex-direction: column;
	align-items: start;
	text-decoration: none;
	color: #fff;
	border-radius: 7px;
	padding: .5rem;
	background: #00B39F;
	transition: all .4s;
	

	&:hover {
		text-decoration: none;
		background: #00d3a9;
		color: white !important;
	}
}

.button-para .link:hover {
	background: #00d3a9;
	cursor: pointer;
}

.button.alt a {
	background: rgba(255,255,255,0.15);
	border-radius: 3px;
	border: 1px solid rgba(255, 255, 255, 0.3);
	padding: 16px 50px;
}

.button.alt a:hover {
	background: #fff;
	color: $brand-color;
}


textarea, input, button, select { font-family: "Open Sans"; font-size: inherit; }

input[type=submit] {
	margin: 2rem 0 0 0;
}

input, textarea {
	display: block;
	width: 100%;
	box-sizing: border-box;
}

textarea {
	resize: vertical;
	height: 150px;
}

label {
	margin: 20px 0 5px 0;
}

label:hover {
	cursor: pointer;
}

input, textarea {
	padding: 10px;
	font-size: 1em;
}

input, textarea {
	-webkit-transition: all 0.30s ease-in-out;
	-moz-transition: all 0.30s ease-in-out;
	-ms-transition: all 0.30s ease-in-out;
	-o-transition: all 0.30s ease-in-out;
	outline: none;
	border: 1px solid #DDDDDD;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
	box-shadow: 0 0 5px rgba(81, 203, 238, 1);
	border: 1px solid rgba(81, 203, 238, 1);
}

@media (min-width:768px) and (max-width: 870px) {
	.button.alt a{
		padding: 16px 25px;
	}
  }

.subscribe-button{	
	@media (max-width:767px) {
		width: 100% !important;
		margin: 3rem auto 0 !important;
	}
}

.subscribe-inputbox{
	padding-left: 0px !important;
}