#integrations {
  background: var(--integrations-bg);
  position: relative;

  & .integrations-heading {
    flex: 0 0 100%;
    display: flex-inline;
    flex-direction: column;
    text-align: center;
    z-index:2;
    overflow: hidden;

    & h1 {
      line-height: 3.75rem;
      font-size: 3.125rem;
      font-weight: 700;
      text-align: center;
      position:relative;
      z-index:2;
    }
    h2 {
      font-weight: 300;
      font-size: 2.2em;
      color: var(--color-secondary-medium);
      z-index:2;
      position:relative;
    }
    .parallel-right {
      position:absolute;
      display: inline;
      top: -8rem;
      right: 0;
      background-color: var(--integrations-bg);
      height:18rem;
      width: 25%;
      z-index:2;
      overflow: hidden;
      -webkit-clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(25% 0, 100% 0, 100% 100%, 0 100%);
    }
    .parallel-left {
      position:absolute;
      display: inline;
      top:-8rem;
      left: 0;
      background-color: var(--integrations-bg);
      height:18rem;
      width: 25%;
      z-index:2;
      overflow: hidden;
      -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);
      clip-path: polygon(0 0, 75% 0, 100% 100%, 0 100%);
    }

    @media (max-width: 1192px) {
      .parallel-left, .parallel-right {
        top: -6rem;
      }
    }
    @media (max-width: 786px) {
      .parallel-left, .parallel-right {
        top: -4.5rem;
      }
    }
    @media (max-width: 600px) {
      .parallel-left, .parallel-right {
        top: 0;
      }
    }
  }

  .integrations-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 3rem;

    .integrations-card {
      box-sizing: border-box;
      width: 5rem;
      height: 5rem;
      padding: 1rem;
      position: relative;
      &-inner {
        position: absolute;
        box-sizing: border-box;
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        transform-style: preserve-3d;
        perspective: 600px;
        transition: 0.5s;
        &:hover .card-front {
          transform: rotateX(-180deg);
        }

        &:hover .card-back {
          transform: rotateX(0deg);
        }

        & .card-back,
        .card-front {
          height: 100%;
          width: 100%;
          background-position: 50% 50%;
          background-size: cover;
          position: absolute;
          padding: 1rem;
          box-sizing: border-box;
          top: 0;
          left: 0;
          backface-visibility: hidden;
          background-color: var(--integrations-bg);
          background-image: var(--background-image-light);
          box-shadow: var(--integrations-box-shadow);
          border-radius: 20px;
          transition: 0.5s;
          color: var(--color-secondary-medium);
        }

        & .card-front {
          transform: rotateX(0deg);
          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        & .card-back {
          transform: rotateX(180deg);
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          margin: 0;
          & p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            padding: 0.3rem;
            margin: 0;
            font-size: 0.8rem;
            font-weight: 600;
            color: var(--color-secondary-medium);
            overflow-x: hidden;
            overflow-y: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            text-align: center;
          }
        }
      }
    }
  }

  .button-container {
    text-align: center;
  }

  & .integrations-3d-link {
    flex: 0 0 100%;
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }
}
