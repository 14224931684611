.single-page {
  display: flex;
  gap: 3rem;
  margin-bottom: 3rem;

  h4 {
    font-weight: bold;
    background-color: var(--color-primary-light);
    padding: .45rem;
    width: 100%;
  }

  li {
    
    > p {
    font-size: .85rem;
    font-weight: 400;
    margin: 0rem;
    }
    > div {
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
    
  }
}

.single-page code {
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  background-color: var(--color-secondary-dark);
  line-height: 1rem;
  color: var(--color-primary-light);
  display: block;
}

.blog-navigation-container {
  display: flex;
  text-decoration: none;
  list-style: none;
  justify-content: space-between;
}
 .modal-image-single {
      width: 15rem;
      img.pattern-snapshot  {
        max-width: 200px;
        cursor: pointer; 
        padding: 0.5rem;
        margin-bottom: 1rem;
        border: 1px solid var(--color-primary-light);
        &:hover {
          border: 1px solid var(--brand-color-primary);
        }
      }
  }

.pre-white-space {
  white-space: pre-wrap;
}

@media screen and (max-width: 1150px) {
  .modal-image-single {
    img {
      width: 70%;
    }
  }
  .single-page {
    .btn-grp {
      justify-content: space-evenly;
      flex-wrap:wrap;
    }
  }
}

.blog-navigation {
  padding-top: 3rem;
}
.pre-white-space {
  white-space: pre-wrap;
}

@media screen and (max-width: 640px) {
  .modal-image-single {
    width: 100%;
  }
  .single-page {
    flex-direction: column;
    .btn-grp {
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  }
}

// @media screen and (max-width: 700px) {
//   .single-page {
//     .btn-grp {
//       align-items: center;
//     }
//   }
// }
