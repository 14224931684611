.callout {
    width: auto;
    height: 100%;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: none;
  
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      width: 31px;
      height: 31px;
      overflow: hidden;
      top: 0;
      right: 0;
      background-color: none;
      border-radius: 0 4px 0 3px;
      content: url('../assets/images/logos/meshery-logo.png');
    }
  
  
    .card {
      display: block;
      position: relative;
      max-width: 262px;
      height: 150px;
      background:var(--background-light-cards);
      border-radius: 4px;
      padding: 25px 24px;
      margin: 12px;
      text-decoration: none;
      z-index: 0;
      overflow: hidden;
      box-shadow:var(--box-shadow-primary);
      
      h3 {
        color: var(--color-secondary-dark);
        font-size: 19px;
        line-height: 24px;
        font-weight: 800;
        margin-bottom: 4px;
        transition: color .4s;
      }
    
    
      p {
        font-size: 17px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-grey-light);
        transition: color .4s;
    
        &.small {
          font-size: 14px;
        }
      }
      
  
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -27px;
        right: -27px;
        background: var(--background-primary);
        height: 32px;
        width: 32px;
        border-radius: 12px;
        transform: scale(1);
        transform-origin: 50% 50%;
        transition: transform 0.4s ease-out;
        
      }
  
      &:hover{
        h3,p{
          color: white;
        }
      &:before {
        transform: scale(20);
      }
    }
    }
  
    .card:hover {
    //   p {
    //     transition: all 0.3s ease-out;
    //     color: rgba(255, 255, 255, 0.8);
    //   }
  
    //   h3 {
    //     transition: all 0.3s ease-out;
    //     color: #ffffff;
    //   }
      .go-corner {
        content: url('../assets/images/logos/meshery-logo-white.png');
      }
    }
  }
