.blog-posts {
  list-style: none;
  padding: 0;

  li {
    margin: 8rem 0;
  }
  li:first-of-type {
    margin-top: 2rem;
  }
}

.featured-image {
  max-width: 750px;
  width: 100%;
  display: flex;
  text-align: center;
  margin: auto;
}

.blog-post {
  .author {
    padding: 30px 0 0 0;
    border: 1px solid #eee;
    margin: 30px 0;
    font-size: 0.8em;

    .square-image {
      width: 125px;
      height: 125px;
      margin-top: 0;
    }
    .blurb {
      text-align: center;
    }
  }

  h3 {
    margin: 1rem 0;
    font-size: 1.75rem;
    a {
      color: var(--color-secondary-medium);
      text-decoration: none;
      font-weight: normal;
      font-size: 1.3em;
    }
  }

  h2 {
    text-align: left;
    text-transform: none; 
    a {
      color: var(--color-secondary-medium);
    }
  }
  h4 {
    font-size: 1.45rem;
  }

  .blog-navigation {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    width: auto;
    overflow: hidden;
    a {
      display: block;
      float: left;
      margin: 1em 0;
    }

    .next {
      color: var(--color-secondary-medium);
      text-align: right;
    }
  }

  .post-details {
    border-bottom: 1px solid var(--brand-color-secondary);
    font-size: 1.25rem;
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding-bottom: 0.5rem;
    .blog-filters {
      display: flex;
      justify-self: flex-start;
      gap: 1rem;
      .blog-filter {
        display: flex;
        justify-self: flex-start;

        a {
          top: -5px;
        }
      }
    }
    a {
      text-decoration: none;
    }

    .post-date {
      display: flex;
      align-self: flex-end;
    }
    .post-author {
      align-self: flex-start;
    }
  }

  .post-content {
    .button {
      margin: 30px 0 0 0;
    }
    li {
      margin: .9rem 0;
      overflow: hidden;
    }
  }
}
.callout {
  border: 1px dashed var(--color-primary-extra-dark);
  padding: 1rem;
  margin-bottom: 1rem;
}
.pagination {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
  .page_number {
    color: var(--color-primary-qtext);
    margin: 0rem 2rem;
  }
}

.blog-filter {
  text-align: center;
  a {
    background: transparent;
    border: 1px solid var(--brand-color-secondary);
    padding: 2px 8px;
    font-size: 0.8rem;
    border-radius: 3px;
    color: var(--color-secondary-dark);
    transition: 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
      background: #00d3a9;
      color: white !important;
    }
  }
}

.blog-filter.cross a {
  padding-right: 8px;

  &:after {
    content: "x";
    font-size: 0.5em;
    position: relative;
    bottom: 4px;
    right: -3px;
  }
}

.code-block {
  padding: 2px;
  background-color: var(--color-primary-qtext);
  color: var(--color-primary-testimonial-wrapper);
  border-radius: 3px;
}
@media screen and (max-width: 750px) {
  .post-details {
    flex-direction: column-reverse;
    gap: 0.3rem;
  }
  .blog-post .post-details .post-date {
    display: flex;
    align-self: flex-start;
  }
  .blog-post .post-details .post-author {
    align-self: flex-start;
  }
}
