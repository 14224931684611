// Style for the testimonials section

.testimonials-wrapper {
    max-width: 100%;
    background: var(--color-primary-testimonial-wrapper);
}

.testimonials-wrapper .slider {
    overflow: hidden;
    padding: 0.5rem 0 0.5rem;
    max-width: 100%;
}

.testimonials-wrapper h2 {
    text-align: center;
    padding-top: 2rem;
    margin: 0 0 20px 0;


}

.type-one-quote-base blockquote:before,
.type-two-quote-base blockquote:before,
.type-three-quote-base blockquote:before {
    content: none;
}

.type-one-quote-base blockquote,
.type-two-quote-base blockquote,
.type-three-quote-base blockquote {
    padding: 0;
    margin: 0;
    font-style: normal;
    quotes: none;
}


.testimonials-wrapper .slider .bq-section {
    padding: 20px;
    margin-bottom: 40px;

}

.testimonials-wrapper .slider .type-one-wrapper {
    margin: 0px 1rem;
}

.type-one-wrapper.type-one-wrapper-boxed {
    max-width: 576px;
    margin: 0 auto;
}


/* ========== Type One ========== */
.type-one-quote {
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 250px;
    box-shadow: var(--box-shadow-primary-quotes);
    border-radius: 10px;
}


.type-one-quote .type-one-quote-pattern {
    flex-basis: 80px;
    background: linear-gradient(180deg, rgba(71, 126, 150, 1) 0%, rgba(0, 179, 159, 1) 35%, rgba(60, 73, 79, 1) 100%);
    border-radius: 10px 0 0 10px;
    font-size: 1.125rem;
    line-height: 28px;
    color: rgb(0, 0, 0);
    font-family: "Qanelas Soft", "Open Sans", sans-serif;
    font-weight: 400 !important;

}

.type-one-quote .type-one-quote-base {
    flex-basis: calc(100% - 80px);
    background-color: var(--color-primary-light-tbase);
    padding: 40px 30px 50px 80px;
    font-size: 11pt;
    line-height: 1.62em;
    border-radius: 0 10px 10px 0;
    margin: 0;
}

.type-one-quote .type-one-quote-qmark {
    position: absolute;
    top: 45px;
    left: 100px;
    font-family: Garamond, Georgia, "Times New Roman", serif;
    font-size: 42pt;
    color: #999999;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    line-height: 28px;

}

.type-one-quote .type-one-quote-userpic {
    position: absolute;
    top: 80px;
    left: 25px;
    width: 90px;
    height: 90px;

    img {
        border-radius: 50%;
        max-width: 100%;
    }
}

.type-one-quote .type-one-quote-text {
    color: var(--color-primary-qtext);
    font-family: "Qanelas Soft", "Open Sans", sans-serif;
}

.type-one-quote .type-one-quote-meta {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 2px dotted var(--color-primary-qmeta);
}

.type-one-quote .type-one-quote-meta .type-one-author,
.type-one-quote .type-one-quote-meta .type-one-source {
    color: var(--color-primary-qmeta);
}

.type-one-quote .type-one-quote-meta .type-one-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.type-one-quote .type-one-quote-meta .type-one-author cite {
    font-style: normal;
}

.type-one-quote .type-one-quote-meta .type-one-source {
    font-size: 9pt;
}

@media screen and (max-width: 768px) {
    .type-one-quote .type-one-quote-pattern {
        flex-basis: 20px;
    }

    .type-one-quote .type-one-quote-base {
        flex-basis: calc(100% - 20px);
        padding: 100px 30px 50px 30px;
    }

    .type-one-quote .type-one-quote-userpic {
        width: 50px;
        height: 50px;
        left: 40px;
        top: 20px;
    }

    .type-one-quote .type-one-quote-qmark {
        left: 100px;
        top: 45px;
    }
}

/* ========== Type Two ========== */
.type-two-quote {
    position: relative;
    box-shadow: var(--box-shadow-primary-quotes);
    border-radius: 10px;
}

.type-two-quote .type-two-quote-pattern {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 80px;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(250deg, rgba(71, 126, 150, 1) 0%, rgba(0, 179, 159, 1) 35%, rgba(60, 73, 79, 1) 100%);
    border-radius: 10px 10px 0 0;
}

.type-two-quote .type-two-quote-pattern .type-two-quote-qmark {
    flex-basis: 100px;
    font-family: Garamond, Georgia, "Times New Roman", serif;
    font-size: 60pt;
    color: #ffffff;
    text-align: center;
    height: 80px;
    line-height: 90pt;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.type-two-quote .type-two-quote-userpic {
    position: absolute;
    top: 45px;
    left: calc(50% - 35px);
    width: 90px;
    height: 90px;

    img {
        border-radius: 50%;
        max-width: 100%;
    }
}


.type-two-quote .type-two-quote-base {
    flex-basis: calc(100% - 80px);
    background-color: var(--color-primary-light-tbase);
    padding: 60px 30px 50px 100px;
    font-size: 11pt;
    line-height: 1.62em;
    border-radius: 0 0 10px 10px;
}

.type-two-quote .type-two-quote-text {
    color: var(--color-primary-qtext);
    font-family: "Qanelas Soft", "Open Sans", sans-serif;
}

.type-two-quote .type-two-quote-meta {
    margin-top: 30px;
    padding-top: 10px;
    border-top: 2px dotted var(--color-primary-qmeta);
    text-align: center;
}

.type-two-quote .type-two-quote-meta .type-two-quote-author,
.type-two-quote .type-two-quote-meta .type-two-quote-source {
    color: var(--color-primary-qmeta);
}

.type-two-quote .type-two-quote-meta .type-two-quote-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.type-two-quote .type-two-quote-meta .type-two-quote-author cite {
    font-style: normal;
}

.type-two-quote .type-two-quote-meta .type-two-quote-source {
    font-size: 9pt;
}

@media screen and (max-width: 768px) {
    .type-two-quote .type-two-quote-base {
        padding-left: 30px;
    }
}

/* ========== Type Three ========== */
.type-three-quote {
    position: relative;
    min-height: 250px;
    border-radius: 10px;
    box-shadow: var(--box-shadow-primary-quotes);
}

.type-three-quote .type-three-quote-base {
    background: linear-gradient(250deg, rgba(71, 126, 150, 1) 0%, rgba(0, 179, 159, 1) 35%, rgba(60, 73, 79, 1) 100%);
    color: #ffffff;
    font-weight: bold;
    padding: 60px;
    border-radius: 10px;
}

.type-three-quote .type-three-quote-meta {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 2px dotted #ffffff;
}

.type-three-quote .type-three-quote-meta .type-three-quote-author {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: 10pt;
    font-weight: bold;
}

.type-three-quote .type-three-quote-meta .type-three-quote-author cite {
    font-style: normal;
}

.type-three-quote .type-three-quote-meta .type-three-quote-source {
    font-size: 10pt;
}

.type-three-quote .type-three-quote-qmark {
    position: absolute;
    top: 125px;
    right: 5px;
    font-size: 280pt;
    color: #ffffff;
    opacity: 0.18;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    line-height: 28px;
}

.type-three-quote .type-three-quote-userpic {
    width: 90px;
    height: 90px;
    margin-right: 20px;

    img {
        border-radius: 50%;
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .type-three-quote .type-three-quote-base {
        padding-left: 40px;
        padding-right: 40px;
    }

    .type-three-quote .type-three-quote-meta {
        flex-direction: column;
        text-align: center;
    }

    .type-three-quote .type-three-quote-meta .type-three-quote-userpic {
        margin: 0 auto;
        margin-bottom: 10px;
    }
}