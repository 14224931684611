@use "./mixins/transitions.scss" as *;
.box-shadow-primary{
    box-shadow: var(--box-shadow-primary);
}

.button{
    border-radius: 7px;
	padding: .5rem;
	transition: all .4s;
    color: #fff ;
	box-shadow: var(--box-shadow-primary);
	border:2px solid var(--color-grey-light);
	// background: #868e96 !important;
	&:active{
		transform: scale(.95);
	}
	&:hover{
		text-decoration: none;
		background: #00B39F !important;
		transform:  translateY(-2px);
    }    
}