:root{

    // Colors that will not change in the dark mode.
    --brand-color-primary:#00D3A9;
    --brand-color-secondary:#00B39F;
    --brand-color-secondary-50: rgba(0, 179, 159, .5);
    --brand-color-tertiary: #477E96;
    --color-white:#fff;
    --color-primary-light:#fff;
    --color-primary-light-dark:#d9e0e2;
    --color-primary-medium-dark: #FFFFFF;
    --color-primary-medium:#f8f9fa;
    --color-primary-dark:#f1f3f5;
    --color-primary-extra-dark:#b2b6bd;
    --color-primary-super-dark: #818c9a;
    --color-secondary-light:#495057;
    --color-secondary-medium:#343a40;
    --color-secondary-dark:#212529;
    --color-grey-light:#868e96;
    --color-primary-light-tbase:#ecf0f3;
    --color-primary-qmeta: #777777;
    --color-primary-qtext: rgb(0,0,0);
    --color-primary-testimonial-wrapper: rgb(250,250,250);
    
    --box-shadow-primary:3px 3px 10px rgba( 134, 142, 150,  .8);
    --integrations-box-shadow:4px 4px 14px #cbced1,
    -12px -12px 20px #fff;
    --integrations-bg:#ecf0f3;
    --integrations-3d-bg:#f3f4f6;
    --box-shadow-primary-quotes: 2px 2px 25px #cecece;

    --image-color: brightness(15%);
    --image-filter-light: invert(27%) sepia(34%) saturate(217%) hue-rotate(154deg) brightness(92%) contrast(95%);
    --image-filter-dark:brightness(40) invert(1);

    --logo-filter:brightness(100%) invert(0);

    --background-primary:linear-gradient(250deg, #477e96 0%, #00b39f 35%, rgb(60, 73, 79) 100%);
    --background-primary-2:linear-gradient(100deg, #477e96 0%, #00b39f 35%, #5a6f79 100%);
    --background-light: linear-gradient(to right top, #fff, #fff);
    --background-light-cards: linear-gradient(to left bottom, #f1f3f5, #f4f5f7, #f7f7f9, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #f7f7f9, #f4f5f7, #f1f3f5);
    --background-grey: linear-gradient(to right bottom, #dee2e6, #d2d7dc, #c5cbd1, #b9c0c7, #adb5bd);
    --background-grey-secondary:linear-gradient(to right top, #868e96, #767e86, #676e76, #585f66, #495057);
    --background-image-light: none;
    --scrollbar-color: #3c494f;

    --background-nav-fixed: #e7eff333;
    --color-nav-fixed: black;

    --action-color-dark: #EBC017;
    --action-color-dark-hover: #C09E0F;
    --action-color-light: #FFF3C5;

    --color-summary-background:#bac1c8;
    --color-summary-background-hover:#8f949a;
    --color-details-background:#d3d8dd;
    --color-components-background: #f9f9f9;;

}

.dark-mode{
    --color-primary-light:#212529;
    --color-primary-light-dark: #202020;
    --color-primary-medium-dark: #464D53;
    --color-primary-medium:#343a40;
    --color-primary-dark:#495057;
    --color-primary-extra-dark:#6c747e;
    --color-primary-super-dark: #818c9a;
    --color-secondary-light:#fff;
    --color-secondary-medium:#f1f3f5;
    --color-secondary-dark:#f1f3f5;
    --color-grey-light:#ced4da;
    --color-primary-light-tbase:#212121;
    --color-primary-qmeta: #EEEEEE;
    --color-primary-qtext: rgb(255,255,255);
    --color-primary-testimonial-wrapper: rgb(0,0,0);
    
    --box-shadow-primary:4px 4px 8px rgba(0, 0, 0, 1);
    --box-shadow-primary-quotes: 2px 2px 25px rgba(0, 0, 0, 0.1);
    // --box-shadow-primary:4px 4px 8px rgba(255, 255, 255, 0.4);

    
    --background-light:linear-gradient(to right top, #3d3d3d, #343434, #2b2b2b, #222222, #1a1a1a, #181818, #161616, #141414, #181818, #1c1c1c, #212121, #252525);
    --background-grey:linear-gradient(to right top, #868e96, #767e86, #676e76, #585f66, #495057);
    --background-grey-secondary:linear-gradient(to right bottom, #dee2e6, #d2d7dc, #c5cbd1, #b9c0c7, #adb5bd);
    --background-light-cards:linear-gradient(to right top, #3d3d3d, #343434, #2b2b2b, #222222, #1a1a1a, #181818, #161616, #141414, #181818, #1c1c1c, #212121, #252525);
    --background-image-light: radial-gradient( circle 100px at 50% 50%,  rgba(167,173,180,0.78) 0%, rgba(103,110,118,0.79) 80%, rgba(103,110,118,1) 100% );
    --scrollbar-color: #00d3a9;


    --image-color: brightness(0) invert(1);
    --image-filter-light:brightness(40) invert(1);
    --image-filter-dark:invert(27%) sepia(34%) saturate(217%) hue-rotate(154deg) brightness(92%) contrast(95%);
    --logo-filter:invert(.9) grayscale(1.6) brightness(1.5);

    --integrations-bg:#676e76;
    --integrations-3d-bg:#222222;
    --integrations-box-shadow:4px 4px 14px #41454a,
    -4px -4px 10px #78808a;

    --background-nav-fixed: #15272f80;
    --color-nav-fixed: white;

    --action-color-dark: #EBC017;
    --action-color-dark-hover: #C09E0F;
    --action-color-light: #FFF3C5;

    --color-summary-background:#333;
    --color-summary-background-hover:#666666;
    --color-details-background:#282828;
    --color-components-background: #202020;
}
