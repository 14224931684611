@use 'mixins/flexbox' as *;
@use "./variables.scss" as *;
.features {
	@extend %flexbox;
	@include flex-flow(wrap);
	padding: 50px 0 30px 0;
	background-color: var(--background-grey-secondary);

	.feature {
		list-style: none;
		padding: 0;
		margin: 0 10px 50px 10px;
		text-align: center;
		background-color: var(--color-primary-light-tbase);
		border-radius: 1.25rem;
		box-shadow: rgba(0, 0, 0, 0.5) 2px 4px 2.5rem 0.5rem;
		width: 100%;

		&_li{
			margin: 0 25px;
			margin-left: 35px;
			text-align: left;
			// change list style to square box with checkmark
			list-style: none;
			&:before {
				content: "✔";
				color: var(--brand-color-secondary);
				font-size: 1.5rem;
				margin-right: 10px;
				// contain the checkmark in a square box
				display: inline-block;
				width: 1rem;
				height: 1rem;
				text-align: center;
				line-height: 1rem;
				background-color: var(--color-primary-medium);
				border: 5px solid var(--color-primary-medium);
				border-radius: 0.25em;
			}
		}
		
		
		.highlighted {
			text-align: center;
			height: 5rem;
			font-weight: 600;
			color: #272727d3;
			font-size: 1.2em;
			padding: 30px 15px;
			// set text color to white
			color: var(--color-secondary-light);
			border-top-right-radius:0;
			border-top-left-radius: 0;
			box-shadow: inset 0 2px 12.5px rgba(0,0,0,0.01);
			text-transform: capitalize;
		}

		.features-cta {
			padding: 0;
			a {
				display: block;
				box-sizing: border-box;
				padding: 2rem 0;
				border-radius: 0 0 2px 2px;
				border: 0;
				color: var(--color-primary-light-tbase);

			}
			.start { 
				text-align: center;
				margin: auto;
				position: relative;
				background-color: var(  --brand-color-primary);
				border-radius: 0 0 1.25rem 1.25rem;
				&:hover {
					background-color: var(--brand-color-secondary);	
					a:hover {
						color: var(--color-secondary-light);
						text-decoration: none;
					}
				}
			}

		}

		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
			min-width: 220px;
		}

		li {
			border-radius: 1rem;
			padding: 10px 0;
		}
	}
}

.container-slider {
    position: relative;
    width: 100%;
    height: 45vh;
    margin-right: 20px;
    margin-top: 3rem;
    -webkit-flex: 1;
        flex: 1;
    
    .img {
      position: absolute;
	  display: flex;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
    .background-img {
		background-image: url('../assets/images/gitops-snapshot.png');
    }
    .foreground-img {
      background-image: url('../assets/images/gitops-yaml-content.png');
      width: 50%;

      @media (min-width: 576px) { 
        width: 50%;
      }

      @media (min-width: 992px) {
        width: 50%;
      }
    }
	&:hover .overlay,
	&:hover .gradient-box-before,
	&:hover .gradient-box-after {
		display: none;
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background: rgba(0, 0, 0, 0.3); // adjust the color and opacity as needed
  }
  
.gradient-box-before {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 10px;
	background-color: #00d3a9;
	color: rgb(255, 255, 255);
	font-size: 15px; 
	border-radius: 4px;
}
.gradient-box-after {
	position: absolute;
	top: 50%;
	right: 25%;
	padding: 10px;
	background-color: #00d3a9;
	color: rgb(255, 255, 255);
	border-radius: 4px;
	font-size: 15px; 
}



@mixin center() {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
.slider {
	position: absolute;
	border-radius: 5px;
	-webkit-appearance: none;
	appearance: none;
	width: 100%;
	height: 100%;
	background: rgba(#f2f2f2, .10) !important;
	outline: none;
	margin: 0;
	transition: all .2s;
	@include center;
	
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 3px;
		height: 30vh;
		background: white;
		cursor: pointer;
	}

	@media (min-width: 300px) {
		&::-webkit-slider-thumb {
			height: 45vh;
		}
	}
	

	@media (max-width: 992px) {
		&::-webkit-slider-thumb {
			height: 45vh;
		}
	}
}



.slider-button {
    $size: 30px;
    pointer-events: none;
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: white;
    left: calc(50% - 18px);
    top: 50%;
    @include center;
    
    @mixin arrow-helper() {
      content: '';
      padding: 3px;
      display: inline-block;
      border: solid #5D5D5D;
      border-width: 0 2px 2px 0;
    }
    &:after {
      @include arrow-helper();
      transform: rotate(-45deg);
    }
    &:before {
      @include arrow-helper();
      transform: rotate(135deg);
    }
  }
