@use "./variables.scss" as *;
.container,
.text-container {
    margin: 0 auto;
    position: relative;
    & div.section-bar {
        background-color: var(--color-primary-dark);
        // width: 100%;
        margin-top: -.5rem;
        margin-bottom: 6rem;
        padding: 1rem;
        > h1 {
            font-size: 1.55rem;
            text-transform: uppercase;
        }
    }
    & h1, h2, h3.subheading {
        text-align: center;
    }
    & h3.subheading {
        font-size: 1.65rem !important;
        font-weight: normal;
        margin-bottom: 2rem;
        font-style: italic;
        color: var(--color-primary-super-dark);
    }
    & h1.community-sub-header,h2.community-sub-header{
        text-align: left;
        @media (max-width:768px) {
            text-align: center;
        }
    }
}

.text-container {
    max-width: 750px;
}

.container {
    max-width: 1140px;

    &.max-container {
        max-width: 100%;
        padding: 0;
    }
}

.post-info{
    color: var(--color-secondary-dark);
}

.anchor {
    position: relative;
}

.anchor a {
    position: absolute;
    left: 0px;
    top: -110px;
}

header {
    background-color: var(--color-primary-light);
    color: $coolgray;
    padding: 1.2vw 0px !important;
    // background: $brand-color; /* Old browsers */
    // background: linear-gradient(to bottom, $brand-color 0%, $middle-gradient-color 100%) no-repeat $brand-color;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    margin-bottom: 5px;

    &.header-shadow {
        box-shadow: #00b39f 1px -27px 57px;
        transition: background-color 0.2s ease;
        text-shadow: none;
        
        a {
            // @media #{$mid-point} {
            //     &:hover {
            //         // border-bottom-color: rgba(80, 80, 80, 0.3);
            //         // border: 1px #ccc solid;
	        //         // border-radius: 5px;
            //     }
            // }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        z-index: 1;
        position: relative;

        &:hover {
            text-decoration: none;
        }
    }

    .project-name {
        font-size: 1.7em;
        line-height: 0;
        display: flex;
        padding: 0;

        a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        img {
            display: block;
            width: auto;
            &:nth-child(1){
                height: 2.3rem;
            }
            &:nth-child(2){
                height: 1.5rem;
            }
        }
        @media (max-width: 568px){
            position: absolute;
            z-index: 2;

            
        img {
            &:nth-child(1){
                height: 2rem;
            }
            &:nth-child(2){
                height: 1.2rem;
            }
        }
            
        }
    }
}
.full {
    width: unset !important;
}

.filledHeader {
    // background: linear-gradient(rgb(57, 80, 84) 0%, rgb(0, 211, 169) 100%);
    // background-size: 100% 1422px;
}

.content {
    // background:var(--background-grey-secondary);
    // box-shadow: #00b39f 1px 7px 57px;
    // box-shadow: inset grey 2px 1px 10px;
    padding: 1px 0 0 0;
    position: relative;
}

.screenshot {
    display: block;
    box-shadow: var(--box-shadow-primary) !important;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    background: #868e96
        url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2244%22%20height%3D%2212%22%20viewBox%3D%220%200%2044%2012%22%3E%3Ccircle%20cx%3D%226%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2222%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3Ccircle%20cx%3D%2238%22%20cy%3D%226%22%20r%3D%224%22%20fill%3D%22%23eee%22%20%2F%3E%3C%2Fsvg%3E")
        4px 4px no-repeat;
    padding: 20px 0 0 0;
    position: relative;
    width: 100%;
}

section {
    padding: 50px 25px;
}


.subtext {
    margin-top: 10px;
    text-align: center;
}

.cta {
    margin: 60px 0;
}

.page h2 {
    margin: 2.5rem 0;
    text-transform: normal; 
}

blockquote {
    padding: 18px 25px;
    margin: 0;
    quotes: "\201C""\201D""\2018""\2019";
    font-style: italic;

    .author {
        display: block;
        font-weight: bold;
        margin: 10px 0 0 0;
        font-size: 0.85em;
        font-style: normal;
    }

    p {
        display: inline;
    }
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

.square-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 25px auto 0 auto;
    position: relative;
    border-radius: 200px;

    img {
        position: absolute;
        left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        width: 100px;
        height: 100px;
    }
}

.page {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    h2{
        margin-bottom: 0;
      } 
}

.center-text {
    text-align: center;
}

.editor-link {
    display: none;
    margin-top: 0;
    .btn {
        border: 0;
        border-radius: 2px;
        width: 100%;
        max-width: 500px;
        box-sizing: border-box;
        font-size: 2rem;
        text-decoration: none;
        padding: 10px 15px;
        margin: 0;
        font-size: 18px;
        cursor: pointer;
        background-color: #f7e064;
        color: #333;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

        &:hover {
            background-color: #f4d525;
            color: #333;
        }
    }
}

table {
    border-collapse: collapse;
}

table,
th,
td {
    border: 1px solid var(--color-secondary-dark);
    padding: 8px;
    color: var(--color-secondary-dark);
}

.code {
    color: white;
    // & p ul {
    text-align: left;
    // }
    a {
        color: white;
    }
}

.highlight-code {
    position: relative;
    border-radius: 5px;
    background: black;
    margin-bottom: 15px;
    overflow:hidden;
    padding: 15px;
}

.toggle .innerDiv {
    width: 100%;
    display: table;
    table-layout: fixed;
    height: 80px; /* any arbitrary height but best at the minimum initial height you would want. */
    // background: #EEE;
    transition-timing-function: linear;
    transition: cubic-bezier(0, 1, 0, 1);
    
    a {
        color: #f1f3f5;
        &:hover {
            // color: var(--brand-color-secondary);
            
            text-decoration: underline !important;
        }
    }

    & h3,p{
        color: var(--color-secondary-dark);
    }

    & p a{
        color: var(--color-secondary-dark);
        font-weight: 600;
    }
}


.hideDiv {
    max-height: 0px;
    pointer-events: none;
    transition: all .4s;
    border-radius: 7px;
    box-shadow: var(--box-shadow-primary);
    overflow: hidden;
    box-sizing: border-box;
    background: var(--background-light);
}

.showDiv {
    pointer-events: all;
    padding: 15px;
    box-sizing: border-box;
    max-height: max-content !important;
}

.docker {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    display: none;
}

.k8s-instructions {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    display: none;
}

#bounce {
    height: 60px;
    width: 60px;
}
// responsive getting started section
#bounce:hover {
    transition: transform 350ms;
    -ms-transform: scale(1.3) translateZ(0); /* IE 9 */
    -webkit-transform: scale(1.3) translateZ(0); /* Safari 3-8 */
    transform: scale(1.3) translateZ(0);
}
#bounceDocker{
    width: 70px;
    margin-top: 10px;
}
#bounceDocker:hover {
    transition: transform 350ms;
    -ms-transform: scale(1.3) translateZ(0); /* IE 9 */
    -webkit-transform: scale(1.3) translateZ(0); /* Safari 3-8 */
    transform: scale(1.3) translateZ(0);
}

hr {
    opacity: 0.4;
    width: auto;
}
#breakWords {
    word-break: break-all;
}

.code-box {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    overflow: auto;
    min-height: 50px;
    line-height: 25px;
    width: 90%;
    font-size: 15px;
    white-space: pre-line;
    color: #fff !important;
}


.code-box::-webkit-scrollbar{
    width: 3px;
    height: 3px;
    border-radius: 3px;
    overflow: hidden;
    padding: 2px 0;
    background-color: #868e96  ;
}

.code-box::-webkit-scrollbar-thumb{
    background-color: #f1f3f5;
    border-radius: 3px;
}

.getting-started-heading {
    font-weight: bold;
    font-size: 75px;
    text-align: left;
    margin-bottom:0px;
}

.overview-heading {
    font-weight: bold;
    width: 100%; 
    text-align: right; 
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 75px;
}


.step-number {
    font-size: 60px;
    opacity: 0.7;
    font-weight: bold; 
}

.step-heading {
    margin: 0;
    font-weight: bold;
}

// responsive getting started
@media screen and (max-width: 1900px) {
    #box {
        width: 40%;
        overflow: hidden;
        margin-left: -60px;
    }
}

@media screen and (max-width: 1440px) {
    #box {
        width: 40%;
        overflow: hidden;
        margin-left: -60px;
    }
}

@media screen and (max-width: 1000px) {
    #box {
        width: 100%;
        font-size: small;
    }
}
.platformIcon {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
    .getting-started-heading {
        font-size: 55px;
    }



    .overview-heading {
        font-size: 55px;
    }

    .step-number {
        font-size: 50px;
    }

    .step-heading {
        font-size: 30px;
    }
    .platformIcon{
        justify-content: center;
        margin-left: 0;
    }

}

//   tooltip
@import url("https://fonts.googleapis.com/css?family=PT+Sans:400,700");

*::before,
*::after {
    box-sizing: inherit;
}

[data-tooltip] {
    cursor: pointer;

    &::before {
        content: attr(data-tooltip);
        position: absolute;
        background-color: #505050;
        border-radius: 10px;
        max-width: 400px;
        font-size: 1.6rem;
        padding: 0.5em;
        opacity: 0;
        top: 180px;
        transition: opacity 0.3s;
    }

    &:hover::before {
        opacity: 1;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: #1a2421;
    text-align: center;
    padding: 7px;
    font-size: 1em;
    border-radius: 10px;
   
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 5rem;
    left: 50%;
    margin-left: -45px;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

@media screen and (max-width: 850px) {
    .tooltip{
        padding: 10px !important;
    }   

}

@media screen and (max-width:360px) {
    section {
        padding: 50px 9px;
        overflow-x: hidden;
    }

}
