#meshkit-hero {
    position: relative;
    height: 500px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.hero-left,
.hero-right {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
}

.hero-left {
    margin-left: -16%;
}

.hero-right {
    width: 100%;
    margin-left: 20%;
}

.hero-left-mobile,
.hero-right-mobile {
    display: none;
}

.brand-kit {
    width: 60%;
    z-index: 5; /* Make sure this layer is above the background images */
    padding: 5%;
}

.brand-kit h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
}

.download-button {
    background-color: #ebc017;
    color: #000;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    padding-bottom: 15px;
    font-size: 1em;
    cursor: pointer;
    -webkit-transition: 450ms all;
    transition: 450ms all;
    text-decoration: none;
    text-transform: capitalize;
}

.download-button:hover,
.download-button:focus {
    background-color: #ebc017;
    box-shadow: 0 2px 10px rgb(255 255 255 / 0.4);
}

.download-button:active {
    box-shadow: 0 2px 10px rgb(255 255 255 / 0.4);
    transform: scale(0.98);
    background-color: #ebc017;
}

.content-body {
    padding: 50px 0px;
}

.content-body-section {
    display: flex;
    flex-direction: column;
}

.content-body-section h1,
.content-body-section h2 {
    text-align: left;
}

.content-body-section h2 {
    margin-top: 0rem !important;
    font-size: 1.5rem;
}

.content-body-section p {
    margin-bottom: 1rem;
}

.content-body-section img {
    width: 85%;
    align-self: center;
}

.content-body-section .download-button-wrapper {
    align-self: end;
    margin-top: 2rem;
}

.nav-fixed {
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
    margin-left: 2rem;
    border-radius: 5px; /* Rounded corners as per the image */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
    padding: 1rem; /* Padding inside the navbar */
    padding-top: 0;
    min-height: max-content;
    min-width: max-content;
    background: var(--background-nav-fixed);
    color: var(--color-nav-fixed);
    font-size: 15px;
    height: 100%;
}

.nav-fixed p {
    font-size: 1rem;
    margin-bottom: 0px !important;
}

.header-link {
    display: block;
    color: var(--color-nav-fixed);
    margin-bottom: 0.5rem;
    margin-top: 1.5rem; /* Space between links */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth transition for hover effect */
    font-size: 1.1rem;
}

.header-link:hover,
.header-link:focus {
    color: #00b39f; /* Color when link is hovered or focused */
    text-decoration: none; /* Ensure underline doesn't re-appear on hover */
}

.logo-container {
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0;
}

.logo-container-img1 {
    flex: 1;
    height: 70%;
    border-right: 2px solid #3d4f57;
    padding: 30px;
}

.logo-container-img2 {
    flex: 2;
    width: 70%;
    padding: 20px;
}

.mobile-image {
    display: none;
}

.color-palette {
    width: 100%;
    display: flex;
    color: white;
    gap: 1rem; /* space between color boxes */
    margin-top: 2rem; /* space above the color palette */
    flex-wrap: wrap; /* Allow color boxes to wrap */
    justify-content: center; /* Distribute items evenly */
    align-items: center;
}

.color-box {
    width: 165px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
}

.color-name {
    margin-top: 0.5rem;
    font-weight: bold;
}

.color-code {
    font-size: 0.8rem;
}

@media (max-width: 1020px) {
    .nav-fixed {
        display: none;
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }

    #meshkit-hero {
        height: 400px;
    }

    .hero-left,
    .hero-right {
        display: none;
    }

    .hero-left-mobile,
    .hero-right-mobile {
        position: absolute;
        top: 0;
        margin-top: 0;
    }

    .hero-left-mobile {
        position: absolute;
        display: flex;
        margin-left: -170rem;
    }

    .hero-right-mobile {
        display: flex;
        width: 100%;
        margin-left: -11rem;
    }

    .brand-kit {
        width: 100%;
        text-align: center; /* Center align the text for mobile */
        padding: 5%;
    }

    .brand-kit h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    .download-button-wrapper {
        width: 100%;
        margin-left: 0;
    }

    .download-button {
        width: 100%;
        margin-left: 0;
    }

    .content-body-section img {
        display: none;
    }

    .content-body-section .mobile-image {
        display: block;
        width: 100%;
    }

    .logo-container {
    flex-direction: column;
    align-items: center;
    }
    
    .logo-container img{
        display: block;
    }

    .logo-container-img2 {
        width: 100%;
        border-top: 2px solid #3d4f57;
        border-left: none;
        padding: 20px 0;
    }

    .logo-container-img1 {
        border-right: none;
        width: 50% !important;
        height: 70%;
        padding: 2rem 0px;
        aspect-ratio: 1/1;
    }
}

@media (max-width: 480px) {
    p {
        font-size: 1em;
    }
    .brand-kit h1 {
        font-size: 2em;
    }
}
